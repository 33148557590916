import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CatalogDetail } from "../../apis/detail-catalog";
import { CatalogCard } from "../../components/cards/catalog-card";
import { Container } from "../../components/container/container";
import { Paragraph } from "../../components/typography/paragraph";
import { Col, message, Radio, Row, Space, Tag as TagAnt } from "antd";
import { Step } from "../../components/step/step";
// @ts-ignore
import renderHTML from "react-render-html";
import { Info } from "../../components/info/info";
import { Tag } from "../../components/tag/tag";
import { SidebarCard } from "../../components/cards/sidebar-card";
import striptags from "striptags";
import { SessionCard } from "../../components/cards/session-card";
import { postCatalogRating } from "../../apis/catalog-ratings";
import { compareObjectsByProperty } from '../../util/compare-objects-by-property'

export interface CatalogProps extends CatalogDetail {
  onPdfClick?: MouseEventHandler;
  onFavoriteClick?: MouseEventHandler;
  onServiceClick?: MouseEventHandler;
}

export const Catalog: FC<CatalogProps> = ({
  name,
  link,
  isFree,
  isOnline,
  updated_at,
  onPdfClick,
  onFavoriteClick,
  onServiceClick,
  description,
  info,
  steps,
  targets,
  tags,
  department,
  category,
  departmentSections,
  averageRatings,
  id,
}) => {
  const [session, setSession] = useState<any>();
  const [currentRatings, setCurrentRatings] = useState({
    count: 0,
    average: 5,
  });

  const recalculateAverageRounded = (
    value: number,
    average: number,
    count: number
  ) => {
    const calculateAverage = (average * count + value) / (count + 1);

    return Math.round(calculateAverage);
  };

  const onSubmitRating = useCallback(
    (value: number) => {
      postCatalogRating(id, { value })
        .then(() => {
          setCurrentRatings(({ average, count }) => ({
            count: count + 1,
            average: recalculateAverageRounded(value, average, count),
          }));

          message.success("Avaliação registrada com sucesso!");
        })
        .catch((err) => {
          let errorMessage = "Não foi possível completar a ação!";

          if (err?.response?.data?.status === 404) {
            errorMessage = "Recurso não encontrado!";
          }
          if (err?.response?.data?.status === 400) {
            errorMessage =
              err?.response?.data?.message ?? "Erro nos dados enviados!";
          }

          message.error(errorMessage);
        });
    },
    [id]
  );

  useEffect(() => {
    if (averageRatings) {
      const { average, count } = averageRatings;
      setCurrentRatings({ average, count });
    }
  }, [averageRatings]);

  useEffect(() => {
    if (departmentSections && departmentSections[0]) {
      setSession(departmentSections[0]);
    }
  }, [departmentSections]);

  const sections = useMemo(() =>
    departmentSections?.sort(compareObjectsByProperty('name')),
    [departmentSections]);

  return (
    <div className="xvia-catalog">
      <div className="xvia-catalog__hearder_container">
        <div className="xvia-home-search__gradient"></div>
      </div>

      <Container className="xvia-catalog__container">
        <div className="">
          <CatalogCard
            title={name}
            isFree={isFree}
            isOnline={isOnline}
            lastUpdate={updated_at}
            rating={currentRatings.average}
            totalRatings={currentRatings.count}
            onPdfClick={onPdfClick}
            onFavoriteClick={onFavoriteClick}
            onServiceClick={onServiceClick}
            link={link}
            onRatingClick={onSubmitRating}
          />
        </div>
        <Row gutter={40}>
          <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
            <div className="xvia-catalog__info_section">
              <h2>O que é este serviço </h2>
              <Paragraph>{striptags(description)}</Paragraph>
            </div>
            {!!steps?.length && (
              <div className="xvia-catalog__info_section">
                <h2>Etapas para a realização deste serviço</h2>
                {steps?.map((step, index) => {
                  return (
                    <div className={"xvia-catalog__info_row"} key={step.id}>
                      {steps[index - 1]?.channel !== step?.channel && (
                        <div style={{ fontSize: 20 }}>
                          {step?.channel === "online" ? (
                            <div className={"xvia-catalog__info_row_cannel"}>
                              Online <span>- Canal de atendimento digital</span>
                            </div>
                          ) : (
                            <div className={"xvia-catalog__info_row_cannel"}>
                              Presencial <span>- Unidades do governo</span>
                            </div>
                          )}
                        </div>
                      )}
                      <Step number={step.orderIndex} title={step?.name}>
                        <Paragraph>{step?.description}</Paragraph>
                        {renderHTML(step?.htmlContent)}
                        <div>
                          {!!step.documents.length && (
                            <div>
                              <h3>Documentos necessários</h3>
                              {step.documents.map((doc) => {
                                return (
                                  <div>
                                    <Info text={doc} icon={"fa-check"} />
                                  </div>
                                );
                              })}{" "}
                            </div>
                          )}
                        </div>
                      </Step>
                    </div>
                  );
                })}
              </div>
            )}
            {!!info?.length && (
              <div className="xvia-catalog__info_section">
                <h2>Informações</h2>
                {info?.map((info) => 
                  !!info.icon && !!info.title && !!info.text ?
                  (
                    <div style={{ marginBottom: 60 }} key={JSON.stringify(info)}>
                      <Step icon={info.icon} title={info.title}>
                        {renderHTML(info.text)}
                      </Step>
                    </div>
                  ) : null
                )}
              </div>
            )}
            {!!tags?.length && (
              <div className="xvia-catalog__info_section">
                <h2>Tags</h2>
                {tags?.map((tag) => {
                  return <Tag href={"#"} key={tag}>{tag}</Tag>;
                })}
              </div>
            )}
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <div className="xvia-catalog__info_section">
              <SidebarCard title={"Unidades de atendimento"}>
                {!!sections?.length ? (
                  <div>
                    <Paragraph>{sections?.length === 1 ? `1 unidade que presta o serviço:` : `${sections?.length} unidades que prestam o serviço:`}</Paragraph>
                    <Radio.Group
                      onChange={(value) => {
                        setSession(value.target.value);
                      }}
                      value={session}
                      className="xvia-catalog__section_list"
                    >
                      <Space direction="vertical">
                        {sections?.map((session) => {
                          return <Radio value={session}>{session.name}</Radio>;
                        })}
                      </Space>
                    </Radio.Group>
                  </div>
                ) : (
                  <Paragraph style={{ padding: 20, textAlign: "center" }}>
                    Nenhuma unidade disponível para este serviço
                  </Paragraph>
                )}
                {session && (
                  <SessionCard
                    mapUrl={session?.mapUrl}
                    name={session?.name}
                    openingTime={session?.openingTime}
                    telephone={session?.telephone}
                    email={session?.email}
                    city={session?.city}
                    address={session?.address}
                    district={session?.district}
                    state={session?.state}
                    complement={session?.complement}
                  />
                )}
              </SidebarCard>
            </div>
            <div className="xvia-catalog__info_section">
              <Paragraph>Categoria</Paragraph>
              <Paragraph style={{ fontWeight: "bold" }}>
                {category?.name}
              </Paragraph>
            </div>
            <div className="xvia-catalog__info_section">
              <Paragraph>Público-alvo</Paragraph>
              {targets?.map((target) => {
                return (
                  <TagAnt className={`xvia-service-card__tag ${target.replace(" ", "-")}`} key={target}>
                    {target}
                  </TagAnt>
                );
              })}
            </div>
            <div className="xvia-catalog__info_section">
              <Paragraph>Órgão responsável</Paragraph>
              <Paragraph style={{ fontWeight: "bold" }}>
                {department?.shortName} - {department?.name}
              </Paragraph>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
